<template>
    <div id="home-frame">
        <header class="columns is-gapless">
            <div class="column">
                <img src="@/assets/logo-black.svg" alt="" @click="$resetCards">
            </div>
            <div class="column menu has-text-right">
                <span>
                    {{currentUser.first_name}} {{currentUser.last_name}}
                    <a @click="$openCard('user-settings')"><b-tooltip label="User Settings" position="is-bottom" class="ml-3">
                        <b-icon pack="fad" icon="user-cog"/>
                    </b-tooltip></a>

                    <a @click="logout"><b-tooltip label="Logout" position="is-bottom" class="ml-3">
                        <b-icon pack="fad" icon="sign-out-alt"/>
                    </b-tooltip></a>
                </span>
            </div>
        </header>

        <main>
            <div class="container start fadeIn" v-show="$TIER.cards.length === 0">
                <div class="columns">
                    <div class="column is-10 is-offset-1">
                        <div class="welcome">
                            <h1><strong>{{welcome}},</strong> {{currentUser.first_name}}</h1>
                            <h3>What would you like to do?</h3>
                        </div>
                        <div class="menu">
                            <div class="menu-item" @click="$openCard('tasks')">
                                <b-icon custom-class="fa-fw" pack="fad" icon="tasks"></b-icon>
                                <span class="menu-title">Your Tasks</span>
                                <span class="menu-tag" v-if="tasks.assigned.total">{{tasks.assigned.total}}</span>
                            </div>
                            <div class="menu-item" @click="$openCard('trips')">
                                <b-icon custom-class="fa-fw" pack="fad" icon="plane"></b-icon>
                                <span class="menu-title">Trips</span>
                                <span class="menu-tag" v-if="tasks.totals.trips">{{tasks.totals.trips}}</span>
                            </div>
                            <div class="menu-item" @click="$openCard('registrations', {tripId: null})">
                                <b-icon custom-class="fa-fw" pack="fad" icon="suitcase-rolling"></b-icon>
                                <span class="menu-title">Registrations</span>
                                <span class="menu-tag" v-if="tasks.totals.registrations">{{tasks.totals.registrations}}</span>
                            </div>
                            <div class="menu-item" @click="$openCard('customers')">
                                <b-icon custom-class="fa-fw" pack="fad" icon="users"></b-icon>
                                <span class="menu-title">Customers</span>
                            </div>
                            <div class="menu-item" @click="$openCard('billing')">
                                <b-icon custom-class="fa-fw" pack="fad" icon="credit-card"></b-icon>
                                <span class="menu-title">Billing</span>
                                <span class="menu-tag" v-if="tasks.totals.bill_due">{{tasks.totals.bill_due}}</span>
                            </div>
<!--                            <div class="menu-item" @click="$openCard('communication')">-->
<!--                                <b-icon custom-class="fa-fw" pack="fad" icon="comments-alt"></b-icon>-->
<!--                                <span class="menu-title">Communication</span>-->
<!--                            </div>-->
<!--                            <div class="menu-item" @click="$openCard('marketing')">-->
<!--                                <b-icon custom-class="fa-fw" pack="fad" icon="search-dollar"></b-icon>-->
<!--                                <span class="menu-title">Marketing</span>-->
<!--                            </div>-->
                            <div class="menu-item" @click="$openCard('reports')">
                                <b-icon custom-class="fa-fw" pack="fad" icon="clipboard-list-check"></b-icon>
                                <span class="menu-title">Reports</span>
                            </div>
<!--                            <div class="menu-item" @click="$openCard('settings')">-->
<!--                                <b-icon custom-class="fa-fw" pack="fad" icon="cogs"></b-icon>-->
<!--                                <span class="menu-title">Settings</span>-->
<!--                            </div>-->
                        </div>
                        <!--                        <div class="sub-menu">-->
                        <!--                            <div class="menu-item">-->
                        <!--                                <b-icon pack="fad" icon="tools"></b-icon>-->
                        <!--                                <span>New Trip</span>-->
                        <!--                            </div>-->
                        <!--                            <div class="menu-item">-->
                        <!--                                <i class="fad fa-user-plus"></i>-->
                        <!--                                <span>New Customer</span>-->
                        <!--                            </div>-->
                        <!--                            <div class="menu-item">-->
                        <!--                                <b-icon pack="fad" icon="tools"></b-icon>-->
                        <!--                                <span>New Registration</span>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                </div>

            </div>

            <stack class="cards" v-show="$TIER.cards.length"/>
        </main>



        <footer class="columns is-gapless">
            <div class="column is-10">
                <ul class="mh-breadcrumb" :class="{'active': $TIER.cards.length}">
                    <li @click="$resetCards()"><span><i class="fad fa-home" style="margin-right: 0"></i></span></li>
                    <li v-for="(card, index) in $TIER.cards" :class="{'active': index >= $TIER.activeCard && index < $TIER.activeCard + $TIER.cardCount}" @click="$setActiveCard(index)">
                        <span><i class="fas" :class="card.definition.icon" v-if="card.definition.icon"></i>{{card.title || card.definition.title || card.definition.component}}</span>
                    </li>
                </ul>
            </div>
            <div class="column has-text-right">
                <span v-if="$TIER.saveAlert.active" class="has-text-warning is-size-7 has-text-weight-bold"><i class="far fa-exclamation-triangle mr-2"></i>Unsaved Changes</span>
            </div>
        </footer>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Stack from "../TIER/components/Stack";
    import {client as http} from "../http_client";

    export default {
        name: 'Home',
        components: {Stack},
        computed: {
            ...mapGetters(['currentUser', 'tasks', 'assignees']),
            welcome: function () {
                const currentHour = new Date().getHours();
                return currentHour >= 4 && currentHour < 12 ? 'Good morning' :
                        currentHour >= 12 && currentHour <= 17 ? 'Good afternoon' :
                                currentHour > 17 || currentHour < 4 ? 'Good evening' : 'Welcome';
            }
        },
        methods: {
            ...mapActions(['signOut', 'updateTasks']),
            logout() {
                console.log('signout');
                this.signOut();
                this.$router.push({name: 'Login'});
            },
            loadTasks() {
                this.updateTasks();
            },
            loadAssignees() {
                http.get('/api/tasks/assignees', {ttl: 50}).then(response => {
                    this.$store.commit('setAssignees', response.data);
                });
            }
        },
        mounted() {
            this.loadTasks();
            this.loadAssignees();
        }
    };

</script>

<style lang="scss">

    #home-frame {

        display: flex;
        flex-direction: column;
        min-height: 100vh;
        overflow: hidden;

        background: #151f08 radial-gradient(#194001, #091b04);

        header {
            padding: 0.8rem 3rem 0;
            margin-bottom: 0;
            color: rgba(255, 255, 255, 0.7);

            img {
                width: 50%;
                max-height: 100px;
                cursor: pointer;
            }

            .menu {
                margin-top: 2rem;
                color: rgb(255, 255, 255);
                font-weight: bold;

                i {
                    margin-left: 1rem;
                    cursor: pointer;

                    &:hover {
                        color: white;
                    }
                }

                a {
                    color: white;
                }
            }

            @media (max-width: 600px) {
                display: none;
            }
        }

        main {
            position: relative;
            flex-grow: 1;

            .start {
                display: flex;
                justify-content: center;
                flex-direction: column;

                transition-delay: 0ms;
                transition-duration: 500ms;

                margin-top: 5%;

                .welcome {
                    color: white;
                    margin-left: 0.3rem;
                    padding: 1rem;

                    h1 {
                        font-size: 2.5rem;
                        color: #ffffff;

                        strong {
                            color: #ffffff;
                        }
                    }

                    h3 {
                        font-size: 22px;
                        color: rgba(255, 255, 255, 0.8);
                    }

                    @media (max-width: 600px) {
                        padding: 1rem;
                        h1 {
                            font-size: 1.3rem;

                        }
                        h3 {
                            font-size: 1rem;
                        }
                    }
                }

                .menu {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 4rem;
                    /*justify-content: space-between;*/

                    .menu-item {
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        color: white;
                        text-align: center;
                        padding: 1.8rem 0 1rem;
                        margin: 1rem 1.1rem 0 0;
                        border-radius: 1.1rem;
                        position: relative;
                        width: 7.5rem;
                        cursor: pointer;
                        transition: all linear 0.15s;

                        &:hover {
                            background: rgba(255, 255, 255, 0.05);
                            border: 1px solid rgba(255, 255, 255, 1);
                        }

                        i {
                            font-size: 2.1rem;
                        }

                        .menu-title {
                            display: block;
                            padding-top: 0.45rem;
                            font-size: 0.85rem;
                        }

                        .menu-tag {
                            position: absolute;
                            top: 0.5rem;
                            right: 0.5rem;
                            background-color: #f9b000;
                            color: #0e2703;
                            padding: 0.2rem 0.45rem 0.1rem;
                            font-size: 11px;
                            font-weight: bold;
                            border-radius: 10px;
                        }

                        @media (max-width: 600px) {
                            width: 100%;
                            text-align: left;
                            padding: 1rem 2rem;

                            i {
                                font-size: 2rem;
                            }

                            .menu-title {
                                display: inline-block;
                                padding: 0 0 0 2rem;
                                margin: 0;
                                font-size: 1rem;
                            }
                        }

                        @media (max-width: 600px) {
                            margin: 0 1rem 1rem 0;
                            padding: 1rem 2rem 0.5rem 2rem;
                        }
                    }

                    @media (max-width: 600px) {
                        margin: 0;
                        padding: 0 0 0 1rem;
                    }
                }

                .sub-menu {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 1rem;

                    .menu-item {
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        color: white;
                        text-align: center;
                        padding: 0.3rem 1.1rem 0.2rem 1.1rem;
                        margin: 1rem 1rem 0 0;
                        border-radius: 1.1rem;
                        position: relative;
                        cursor: pointer;
                        transition: all linear 0.15s;

                        &:hover {
                            background: rgba(255, 255, 255, 0.05);
                            border: 1px solid rgba(255, 255, 255, 1);
                        }

                        i {
                            margin-right: 0.6rem;
                        }

                        .menu-title {
                            font-size: 0.85rem;
                        }

                        .tag {
                            background-color: white;
                            color: #336699;
                            padding: 0.23rem 0.55rem;
                            font-size: 11px;
                            font-weight: bold;
                            border-radius: 10px;
                            margin-left: 0.7rem;
                        }
                    }
                }


            }

            .cards {
            }

        }

        footer {
            color: white;
            padding: 2rem 3rem 1rem;

            .mh-breadcrumb {
                list-style: none;
                margin: 0;
                padding: 0;
                font-size: 0.75rem;

                transition-delay: 0ms;
                transition-duration: 500ms;
                opacity: 0;

                &.active {
                    opacity: 1;
                }

                li {
                    display: inline-block;
                    cursor: pointer;

                    i {
                        margin-right: 0.5rem;
                    }

                    span {
                        display: inline-block;
                        margin-bottom: 3px;
                        border-bottom: 1px solid transparent;
                    }

                    &.active span {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    }

                    &::after {
                        font-family: "Font Awesome 5 Pro", serif;
                        font-weight: 400;
                        content: "\f054";
                        color: rgba(255, 255, 255, 0.3);
                        font-size: 0.8rem;
                        margin: 0 0.7rem;
                    }

                    &:last-child::after {
                        content: ""
                    }
                }
            }

            @media (max-width: 600px) {
                display: none;
            }
        }
    }

</style>
